import React from 'react';
import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageHeading from 'components/MainNav/PageHeading';
import { Breakpoint } from '@mui/system';

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[];
  heading: string;
  artist?: string;
  artistId?: string;
  breadcrumb?: string;
  image?: string;
  width?: Breakpoint;
  ogUrl?: string;
  richResults?: object;
  meta?: {
    description: string;
    title?: string;
    noIndex?: boolean;
  };
}

const PageContentContainer = ({
  children,
  heading,
  artist,
  artistId,
  breadcrumb,
  image,
  width = 'xl',
  ogUrl,
  richResults,
  meta: { description, title, noIndex } = {
    description:
      'Get news, events, setlists, videos and merch for all your favourite artists, all in one place',
  },
}: Props) => {
  const nonce = typeof window !== 'undefined' ? window.__NONCE__ : '';

  return (
    <>
      <Helmet>
        <title>{breadcrumb === 'homepage' ? '' : title || heading}</title>
        <meta charSet="utf-8" />
        <meta property="og:url" content={ogUrl || 'https://ziki.fm/'} />
        <meta property="og:title" content={title || heading} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
        <meta property="og:image" content={image || '/srv/images/zikifm-logo.jpg'} />
        {/* TODO: Get default logo image*/}
        {richResults && (
          <script type="application/ld+json" nonce={nonce}>
            {JSON.stringify(richResults)}
          </script>
        )}
        {noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <Container maxWidth={width} style={{ position: 'relative' }}>
        <PageHeading
          heading={heading}
          breadcrumb={breadcrumb}
          artist={artist}
          artistId={artistId}
        />
        {children}
      </Container>
    </>
  );
};

export default PageContentContainer;
